<template>
  <v-app>
    <div>
      <!-- Header Alert -->
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text">Jadwal <b>Kalender Akademik</b></div>
        <b-button
          squared
          class="mr-2"
          variant="danger"
          @click="$router.push('/academic-calendars/add-holidays')"
          v-if="btnAccess"
          >Tambah Hari Libur</b-button
        >
        <b-button
          squared
          variant="info"
          @click="$router.push('/academic-calendars/add')"
          v-if="btnAccess"
          >Buat Kegiatan</b-button
        >
      </b-alert>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
                <v-sheet height="64" v-if="calendarYear">
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-menu bottom right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          color="grey darken-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span>Tahun</span>
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="setType('day')">
                          <v-list-item-title>Hari</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="setType('week')">
                          <v-list-item-title>Minggu</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="setType('month')">
                          <v-list-item-title>Bulan</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="setType('4day')">
                          <v-list-item-title>4 Hari</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="$emit('calendarYear')">
                          <v-list-item-title>Tahun</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                </v-sheet>
              <div class="row" v-if="calendarYear">
                <template v-for="(month, index) in months">
                  <div class="col-md-4" :key="index">
                    <!-- <h6 class="text-center">{{month.month_name}} {{month.year}}</h6> -->
                    <YearCalendar
                      :orderMonth="month.order_month"
                      :year="month.year"
                      :currentOrder="index"
                      :totalOrder="months.length"
                      :events="events"
                      @lastOrder="handleLastOrder"
                    />
                  </div>
                </template>
              </div>
              <Calendar @calendarYear="setCalendarYear" v-if="!calendarYear" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import Table from "@/view/components/academic-calendars/Table.vue";
import Calendar from "@/view/components/academic-calendars/Calendar.vue";
import YearCalendar from "@/view/components/academic-calendars/CalendarYear.vue";

export default {
  name: "master-academic-calendars",
  components: {
    Table,
    Calendar,
    YearCalendar,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        now: new Date().toISOString().substr(0, 10),
      },
      typeToLabel: {
        month: "Bulan",
        week: "Minggu",
        day: "Hari",
        "4day": "4 Days",
      },
      calendarYear: false,
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
      ],
      items: [],
      orderMonth: {
        Januari: 1,
        Februari: 2,
        Maret: 3,
        April: 4,
        Mei: 5,
        Juni: 6,
        Juli: 7,
        Agustus: 8,
        September: 9,
        Oktober: 10,
        November: 11,
        Desember: 12,
      },
      school_years: [],
      months: [],
      // kalender akademik 1 tahun
      events: [],
      events_form: {
        id: "",
        name: "",
        start: "",
        end: "",
        color: "",
        parent_id: "",
        description: "",
        school_year_id: "",
        is_school: "",
        is_absent: "",
      },
      // access
      btnAccess: false,
    };
  },
  methods: {
    setCalendarYear() {
      this.calendarYear = true;
    },
    setType(type){
      this.$root.$emit('typeCalendar', type)
      this.calendarYear = false
    },
    async getSChoolYearActive() {
      let response = await module.paginate("api/school-years/month");

      this.school_years = response.data;
      this.months = response.data[0].months;
      //("schoolyear", this.months);

      // setTimeout(() => {
      //   this.display = ''
      // }, 9000);
    },
    handleLastOrder() {
      this.display = "";
    },
    async getSchedules() {
      this.events = [];
      // let today = new Date().toISOString().substr(0, 10);
      let filterParams = `&now=${this.filter.now}`;
      let response = await module.paginate(
        `api/academic-calendars`,
        `?page=${this.currentPage}&page_size=500${filterParams}`
      );
      //("calendar", response);
      this.items = response.data;
      for (let a = 0; a < this.items.length; a++) {
        this.events_form.id = this.items[a].id;
        this.events_form.name = this.items[a].name;
        // this.events_form.start = this.items[a].start_date;
        // this.events_form.end = this.items[a].due_date;
        this.events_form.start = new Date(this.items[a].start_date)
          .toISOString()
          .substr(0, 10);
        this.events_form.end = new Date(this.items[a].due_date)
          .toISOString()
          .substr(0, 10);
        this.events_form.parent_id = this.items[a].parent_id;
        this.events_form.description = this.items[a].description;
        this.events_form.school_year_id = this.items[a].school_year_id;
        this.events_form.is_school = this.items[a].is_school;
        this.events_form.is_absent = this.items[a].is_absent;
        if (this.items[a].is_school == 0 && this.items[a].parent_id == 0) {
          this.events_form.color = "red";
        } else if (
          this.items[a].is_school == 1 &&
          this.items[a].parent_id == 0
        ) {
          this.events_form.color = "green";
        } else if (
          this.items[a].is_school == 1 &&
          this.items[a].parent_id != 0
        ) {
          this.events_form.color = "deep-purple";
        }

        let clone = { ...this.events_form };
        this.events.push(clone);
        if (a + 1 == this.items.length) {
          this.dataLoaded = true;
        }
      }

      //("event", this.events);
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "8008") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kalender Akademik" }]);
    // this.pagination();
    this.checkAccess();
    this.getSChoolYearActive();
    this.getSchedules();
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"64"}},[_c('v-toolbar',{staticClass:"text-center",attrs:{"flat":""}},[(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e()],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"locale":"id-ID","color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"type":_vm.type},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[(
                  _vm.selectedEvent.parent_id == 0 && _vm.selectedEvent.is_school == 0
                )?_c('v-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"","title":"Jadikan hari masuk"},on:{"click":function($event){return _vm.confirmSetActivity(_vm.selectedEvent)}}},[_c('v-icon',[_vm._v("fas fa-book-open")])],1):_vm._e(),(
                  _vm.selectedEvent.parent_id == 0 && _vm.selectedEvent.is_school == 1
                )?_c('v-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"","title":"Jadikan hari libur"},on:{"click":function($event){return _vm.confirmSetHoliday(_vm.selectedEvent)}}},[_c('v-icon',[_vm._v("fas fa-book")])],1):_vm._e(),_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({style:('z-index: 99'),attrs:{"dark":"","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-height":"300px","min-width":"30%","tile":""}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary","min-width":"150"}},[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push(
                            `/academic-calendars/edit/${_vm.selectedEvent.id}`
                          )}}},[_c('v-list-item-content',[_vm._v(" Edit ")])],1),(_vm.selectedEvent.parent_id != 0)?_c('v-list-item',[_c('v-list-item-content',{on:{"click":function($event){return _vm.deleteActivity(_vm.selectedEvent.id)}}},[_vm._v(" Hapus ")])],1):_vm._e()],1)],1)],1)],1)],1),_c('v-card-text',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.description)}})]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animate__animated animate__fadeIn"},[_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
            width: field.key === 'action' ? '25%' : '',
          })})})}},{key:"cell(start_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.setDate(data.item.start_date))+" ")]}},{key:"cell(due_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.setDate(data.item.due_date))+" ")]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.$router.push({
              path: '/academic-calendars/detail/' + data.item.id,
            })}}},[_c('i',{staticClass:"fas fa-eye px-0"})]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Edit"},on:{"click":function($event){return _vm.$router.push({
              path: '/academic-calendars/edit/' + data.item.id,
            })}}},[_c('i',{staticClass:"fas fa-edit px-0"})]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-danger",attrs:{"size":"sm","title":"Hapus"},on:{"click":function($event){return _vm.deleteData(data.item.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})])]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
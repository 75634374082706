<template>
  <div class="animate__animated animate__fadeIn">
    <!-- Filter -->
    <!-- <div class="row justify-content-end mt-n3">
                        <div class="col-md-4">
                          <b-input-group>
                            <template #prepend>
                              <b-input-group-text squared>
                                <b-icon-search></b-icon-search>
                              </b-input-group-text>
                            </template>
                            <b-form-input
                              type="text"
                              v-model="filter.name"
                              placeholder="Cari Nama Sekolah"
                              @keyup="filterByName"
                            ></b-form-input>
                          </b-input-group>
                        </div>
                      </div> -->
    <!-- table -->
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      class="mt-3"
      responsive
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width: field.key === 'action' ? '25%' : '',
          }"
        />
      </template>

      <template #cell(start_date)="data">
          {{setDate(data.item.start_date)}}
      </template>

      <template #cell(due_date)="data">
          {{setDate(data.item.due_date)}}
      </template>

      <template #cell(action)="data">
        <b-button
          size="sm"
          class="mr-1 btn-info"
          v-b-tooltip.hover
          title="Detail"
          @click="
            $router.push({
              path: '/academic-calendars/detail/' + data.item.id,
            })
          "
          ><i class="fas fa-eye px-0"></i
        ></b-button>
        <b-button
          size="sm"
          class="mr-1 btn-success"
          v-b-tooltip.hover
          title="Edit"
          @click="
            $router.push({
              path: '/academic-calendars/edit/' + data.item.id,
            })
          "
          ><i class="fas fa-edit px-0"></i
        ></b-button>
        <b-button
          size="sm"
          class="btn-danger"
          v-b-tooltip.hover
          title="Hapus"
          @click="deleteData(data.item.id)"
          ><i class="fas fa-trash px-0"></i
        ></b-button>
      </template>
    </b-table>

    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
export default {
  data() {
    return {
      items: [],
      perPage: 20,
      totalRows: 1,
      currentPage: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "school_year_name",
          label: "Tahun Ajaran",
          sortable: true,
        },
        {
          key: "name",
          label: "Kegiatan",
          sortable: true,
        },
        {
          key: "start_date",
          label: "Mulai",
          sortable: true,
        },
        {
          key: "due_date",
          label: "Selesai",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap"
        },
      ],
    };
  },
  methods: {
    setDate(dateTime){
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let date = new Date(dateTime).toLocaleDateString("id-ID", options)
        let time = new Date(dateTime).toTimeString().substr(0, 8)

        return date + " " + time
    },
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },
    async pagination() {
      let today = new Date().toISOString().substr(0, 10)
      let filterParams = `&now=${today}`;

      let response = await module.paginate(
        `api/academic-calendars`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },
    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/academic-calendars/" + id);
      // If Deleted
      if (result) {
        this.getMaterials();
      }
    },
  },
  mounted() {
    this.pagination();
  },
};
</script>

<style>
</style>